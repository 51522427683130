import React from 'react';
import lsm from '../../assets/logo.png'
import {Link} from 'react-router-dom'

const Footer = () => {
    return (
        <div>
           	{/* <!-- Footer --> */}
		<footer class="footer" >
			{/* <!-- Footer Top --> */}
			<div class="footer-top">
				<div class="container">
					<div class="row">
						<div class="col-lg-4 col-md-6 col-12">
							{/* <!-- Footer About -->		 */}
							<div class="single-widget footer-about widget">	
								<div class="logo">
									<div class="img-logo">
										<a class="logo" href="index.html">
											<img class="img-responsive" width="35%" src={lsm} alt="logo"/>
										</a>
									</div>
								</div>
								<div class="footer-widget-about-description">
									<p>The Lahore school of management is a forward looking institute operating under the umbrella of University of Education.</p>
								</div>	
								<div class="social">
									{/* <!-- Social Icons --> */}
									<ul class="social-icons">
										<li><a class="facebook" href="https://www.facebook.com/lsmlahore1/about/?ref=page_internal" target="_blank"><i class="fa fa-facebook"></i></a></li>
										<li><a class="instagram" href="https://www.instagram.com/lsmlahore_/?hl=en" target="_blank"><i class="fa fa-instagram"></i></a></li>
										<li><a class="whatsapp" href="https://web.whatsapp.com/" target="_blank"><i class="fa fa-whatsapp"></i></a></li>
										<li><a class="whatsapp" href="#" target="_blank"><i class="fa fa-youtube"></i></a></li>
									</ul>
								</div>
								{/* <div class="button"><a href="#" class="bizwheel-btn">About Us</a></div> */}
							</div>		
							{/* <!--/ End Footer About -->		 */}
						</div>
						<div class="col-lg-4 col-md-6 col-12">
							{/* <!-- Footer Links -->		 */}
							<div class="single-widget f-link widget">
								<h3 class="widget-title">Lahore School of Managment</h3>
								<ul>
									<li><Link to="/aboutus" >About Us</Link></li>
									<li><Link to="/lsm-facilities" >Our Services</Link></li>
									<li><Link  to="/vision&mission">Vision and mission</Link></li>
									<li><Link to="/contactus">Contact us</Link></li>
									<li><Link to="/lsmevents">LSM Events</Link></li>
								</ul>
							</div>			
							{/* <!--/ End Footer Links -->	/		 */}
						</div>
				
						<div class="col-lg-4 col-md-6 col-12">	
							{/* <!-- Footer Contact -->		 */}
							<div class="single-widget footer_contact widget">	
								<h3 class="widget-title">Contact</h3>
								<ul class="address-widget-list">
									<li class="footer-mobile-number"><i class="fa fa-phone"></i>0423 5111184</li>
									<li class="footer-mobile-number"><i class="fa fa-whatsapp"></i>+92 310 3336480</li>
									<li class="footer-mobile-number"><i class="fa fa-envelope"></i>info@lsm.edu.pk</li>
									<li class="footer-mobile-number"><i class="fa fa-map-marker"></i>327, 2C1 Township, Lahore.</li>
								</ul>
							</div>		
							{/* <!--/ End Footer Contact -->						 */}
						</div>
					</div>
				</div>
			</div>
			{/* <!-- Copyright --> */}
			<div class="copyright">
				<div class="container">
					<div class="row">
						<div class="col-12">
							<div class="copyright-content">
								{/* <!-- Copyright Text --> */}
                                <p>Copyright © 2021. Lahore School of Managment (LSM), Pakistan - All Rights Reserved</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <!--/ End Copyright --> */}
		</footer>
        </div>
    )
}

export default Footer;
