
import {admission_URL} from '../utils/urls';



export const ADMISSION_SUCCESSFUL = 'ADMISSION_SUCCESSFUL';
export const ADMISSION_FAILLURE = 'ADMISSION_FAILLURE';
export const ADMISSION_PENDING = 'ADMISSION_PENDING';
export const RESET_ADMISSION = 'RESET_ADMISSION'


export const emptyAdmissionStore = () => ({
	types : [RESET_ADMISSION],
	meta : {
		type: 'reset',
	}
});



export const admissionAction = (value) => {
	return {
		types : [ADMISSION_PENDING , ADMISSION_SUCCESSFUL , ADMISSION_FAILLURE],
		URL : admission_URL,
		options: {
			method: 'post'
		},
		payload: value,
		meta: {
			type: 'api',
		},
	}
}