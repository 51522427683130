import {login_URL , register_URL} from '../utils/urls';

export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const REGISTER_PENDING = 'REGISTER_PENDING';
export const REGISTER_SUCCESSFUL = 'REGISTER_SUCCESSFUL';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const RESET_REGISTER = 'RESET_REGISTER'
export const RESET_LOGIN = 'RESET_LOGIN'



export const resetRegisterStore = () => ({
	types : [RESET_REGISTER],
	meta : {
		type: 'reset',
	}
});


export const resetLoginStore = () => ({
	types : [RESET_LOGIN],
	meta : {
		type: 'reset',
	}
});

export const loginCalled = (value) => ({
	types: [LOGIN_PENDING,LOGIN_SUCCESSFUL, LOGIN_FAILURE],
	URL: login_URL,
	options: {
		method: 'post'
	},
	payload: value,
	meta: {
		type: 'api',
	},
});


export const registerCalled = (value) => ({
	types: [REGISTER_PENDING,REGISTER_SUCCESSFUL ,  REGISTER_FAILURE],
	URL: register_URL,
	options: {
		method: 'post'
	},
	payload: value,
	meta: {
		type: 'api',
	},
});


