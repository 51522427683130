import React from 'react';
import { Route , Redirect } from 'react-router-dom';

const ProtectedRoute = ({ path, Component }) => {
	const auth_token = localStorage.getItem('auth_token')



	return (
		<Route 
			path={path} 
			render={props => {
				if(!auth_token) return <Redirect to="/admission" />;
				return <Component {...props} />;
			}}
		/>);
};

export default ProtectedRoute;
