import React, { useState, useEffect } from 'react';
import logo from '../../assets/lsm.png'
import monogram from '../../assets/uoe.png';
import { Link } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText
} from 'reactstrap';

const TopNavbar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [navAnimate, setnavAnimate] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  })

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setnavAnimate(true)
    }
    else {
      setnavAnimate(false)
    }
  }

  const toggle = () => setIsOpen(!isOpen);


  return (
    <div className={`navo ${navAnimate ? 'main__navbar' : 'main__navbar__navScroll'}`} >
      <div className="container-fluid" >
        <Navbar color="fadded" light expand="md" >
          <NavbarBrand href="/"> <img className="img-responsive main__navbar__logo" src={logo} alt="Logo" /> </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="m-auto" navbar>
              <NavItem><NavLink tag={Link} to="/" activeClassName="active">Home</NavLink></NavItem>

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav>LSM <i className='fa fa-caret-down' /></DropdownToggle>
                <DropdownMenu>
                  <DropdownItem className="dropdow" tag={Link} to="/aboutus">About Us</DropdownItem>
                  <DropdownItem className="dropdow" tag={Link} to="/director-message">Director</DropdownItem>
                  <DropdownItem className="dropdow" tag={Link} to="/vision&mission">Vision And Mission</DropdownItem>
                  <DropdownItem className="dropdow" tag={Link} to="/gallery">LSM Gallery</DropdownItem>
                  {/* <DropdownItem className="dropdow" tag={Link} to="/lsmevents">LSM Events</DropdownItem> */}
                </DropdownMenu>
              </UncontrolledDropdown>


              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav>Admission <i className='fa fa-caret-down' /></DropdownToggle>
                <DropdownMenu>
                  <DropdownItem className="dropdow" tag={Link} to="/offeredProgram">Offered Program</DropdownItem>
                  <DropdownItem className="dropdow" tag={Link} to="/admission-schedule">Admission Schedule</DropdownItem>
                  <DropdownItem className="dropdow" tag={Link} to="/how-to-apply">How To Apply</DropdownItem>
                  <DropdownItem className="dropdow" tag={Link} to="/eligiblity">Eligibility Criterial</DropdownItem>
                  <DropdownItem className="dropdow" tag={Link} to="/fee-structure">Fee Structure</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>




              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav>Students <i className='fa fa-caret-down' /></DropdownToggle>
                <DropdownMenu>
                  <DropdownItem className="dropdow" tag={Link} to="/academic-calender">Academic Calender</DropdownItem>
                  <DropdownItem className="dropdow" tag={Link} to="/grading">Grading</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>


              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav>Services <i className='fa fa-caret-down' /></DropdownToggle>
                <DropdownMenu>
                  <DropdownItem className="dropdow" tag={Link} to="/lsm-facilities">Campus Facilities</DropdownItem>
                  <DropdownItem className="dropdow" tag={Link} to="/lsm-counseling">Counseling and Mental Wellness</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>


              <NavItem> <NavLink tag={Link} to="/contactus">Contact Us</NavLink> </NavItem>

              <NavItem> <NavLink tag={Link} to="/lsmevents">LSM Events</NavLink> </NavItem>


              {/* <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav>Safety Certifications  <i className='fa fa-caret-down' /></DropdownToggle>
                <DropdownMenu>
                  <DropdownItem className="dropdow" tag={Link} to="/nebosh-igs">NEBOSH IGC </DropdownItem>
                  <DropdownItem className="dropdow" tag={Link} to="/iosh-ms">IOSH MS</DropdownItem>
                  <DropdownItem className="dropdow" tag={Link} to="/safety-officer">Safety Officer</DropdownItem>
                  <DropdownItem className="dropdow" tag={Link} to="/occupational-safety-for-engineer">Occupational Safety for Engineers</DropdownItem>
                  <DropdownItem className="dropdow" tag={Link} to="/fire-safety">Fire Safety</DropdownItem>
                  <DropdownItem className="dropdow" tag={Link} to="/first-aid">First Aid</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}



              <div className="admission-responsive-link" >
                <NavItem> <NavLink tag={Link} to="/admission">Admission</NavLink> </NavItem>
              </div>

            </Nav>
            <NavbarText>  <img className="main__navbar__logo uoe-logo" src={monogram} alt="Logo" /> </NavbarText>
          </Collapse>
        </Navbar>
      </div>
    </div>
  );
}

export default TopNavbar;
