import React, { useEffect } from 'react'
import './styles/_main.scss'
import TopNavbar from './module/Navbar/TopNavbar'
import { ToastContainer } from 'react-toastify';
import Footer from './module/Footer/Footer'
import TopBar from './module/TopBar/TopBar';

// Loader
import Loader from './components/loader';

// import Home from './layout/home/Home';
// import AdmissionForm from './layout/admissionForm/AdmissionForm';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import ContactUs from './layout/contactUs/ContactUs';
// import VisionMission from './layout/visionMission/VisionMission';
// import About from './layout/about/About';
// import Eligibility from './layout/eligibilityCretireia/Eligibility';
// import SignUp from './layout/signup/SignUp';
// import OfferedPrograme from './layout/offeredProgram/OfferedPrograme';

// Programs
// import BBa from './layout/bba/bba';
// import Bsit from './layout/bsit/Bsit';
// import Bscs from './layout/bscs/bscs';
// import Bsed from './layout/bsed/bsed';
// -----
// import DirectorMsg from './layout/directorMsg/DirectorMsg';
// import Admission from './layout/admission/Admission';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
// import AdmissionSchedule from './layout/admissionSchedule/AdmissionSchedule';
// import HowToApply from './layout/howToApply/HowToApply';
// import FeeStructure from './layout/feeStructure/FeeStructure';
// import Gallery from './layout/gallery/Gallery';
// import Gradding from './layout/gradding/Gradding';
// import AcademicCalender from './layout/academicCalender/AcademicCalender';
// import LsmFacitities from './layout/LsmFacitities/LsmFacitities';
// import Conseling from './layout/councling/Conseling';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import FireSafetu from './layout/Nbosh/FireSafetu';
// import Iosh_ms from './layout/Nbosh/Iosh_ms';
// import OccupationSafety from './layout/Nbosh/OccupationSafety';
// import SafetyOfficer from './layout/Nbosh/SafetyOfficer';
// import NBOSH_IGS from './layout/Nbosh/NBOSH_IGS'
// import FirstAid from './layout/Nbosh/FirstAid';
import ScrollToTop from './scrollToTop'

// LSM Events And Functions
// import lsmevents from './layout/lsmEventGallery/lsmevents';
// import LSMStudents from './layout/lsmEventGallery/LSMStudents/LSMStudents';
// import Graduate2022 from './layout/lsmEventGallery/GraduateStudents/Graduate2022';
// import NathiyaGali from './layout/lsmEventGallery/NathiyaGali/NathiyaGali';
// import MushkpuriTop from './layout/lsmEventGallery/MushkpuriTop/MushkpuriTop';
// import ThemeDay from './layout/lsmEventGallery/ThemeDay/ThemeDay';

const Home = React.lazy(() => import('./layout/home/Home'));
const AdmissionForm = React.lazy(() => import('./layout/admissionForm/AdmissionForm'));
const ContactUs = React.lazy(() => import('./layout/contactUs/ContactUs'));
const VisionMission = React.lazy(() => import('./layout/visionMission/VisionMission'));
const About = React.lazy(() => import('./layout/about/About'));
const Eligibility = React.lazy(() => import('./layout/eligibilityCretireia/Eligibility'));
const SignUp = React.lazy(() => import('./layout/signup/SignUp'));
const OfferedPrograme = React.lazy(() => import('./layout/offeredProgram/OfferedPrograme'));

// Programs
const BBa = React.lazy(() => import('./layout/bba/bba'));
const Bsit = React.lazy(() => import('./layout/bsit/Bsit'));
const Bscs = React.lazy(() => import('./layout/bscs/bscs'));
const Bsed = React.lazy(() => import('./layout/bsed/bsed'));

const DirectorMsg = React.lazy(() => import('./layout/directorMsg/DirectorMsg'));
const Admission = React.lazy(() => import('./layout/admission/Admission'));
const AdmissionSchedule = React.lazy(() => import('./layout/admissionSchedule/AdmissionSchedule'));
const HowToApply = React.lazy(() => import('./layout/howToApply/HowToApply'));
const FeeStructure = React.lazy(() => import('./layout/feeStructure/FeeStructure'));
const Gallery = React.lazy(() => import('./layout/gallery/Gallery'));
const Gradding = React.lazy(() => import('./layout/gradding/Gradding'));

const AcademicCalender = React.lazy(() => import('./layout/academicCalender/AcademicCalender'));
const LsmFacitities = React.lazy(() => import('./layout/LsmFacitities/LsmFacitities'));
const Conseling = React.lazy(() => import('./layout/councling/Conseling'));
const FireSafetu = React.lazy(() => import('./layout/Nbosh/FireSafetu'));
const Iosh_ms = React.lazy(() => import('./layout/Nbosh/Iosh_ms'));
const OccupationSafety = React.lazy(() => import('./layout/Nbosh/OccupationSafety'));
const SafetyOfficer = React.lazy(() => import('./layout/Nbosh/SafetyOfficer'));
const NBOSH_IGS = React.lazy(() => import('./layout/Nbosh/NBOSH_IGS'));
const FirstAid = React.lazy(() => import('./layout/Nbosh/FirstAid'));

// LSM Events
const lsmevents = React.lazy(() => import('./layout/lsmEventGallery/lsmevents'));
const LSMStudents = React.lazy(() => import('./layout/lsmEventGallery/LSMStudents/LSMStudents'));
const Graduate2022 = React.lazy(() => import('./layout/lsmEventGallery/GraduateStudents/Graduate2022'));
const NathiyaGali = React.lazy(() => import('./layout/lsmEventGallery/NathiyaGali/NathiyaGali'));
const MushkpuriTop = React.lazy(() => import('./layout/lsmEventGallery/MushkpuriTop/MushkpuriTop'));
const ThemeDay = React.lazy(() => import('./layout/lsmEventGallery/ThemeDay/ThemeDay'));





function App() {


  useEffect(() => {
    // AOS.init({ duration: 2000 })
    AOS.init({ disable: 'mobile', duration: 1000 });
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div>
      <ToastContainer />
      <Router>
        <React.Suspense fallback={<Loader />}>
          <TopBar />
          <TopNavbar />
          <ScrollToTop />
          <Switch>
            <Route exact path="/" component={Home} />
            <ProtectedRoute path="/admissionForm" Component={AdmissionForm} />
            <Route path="/contactus" component={ContactUs} />
            <Route path="/vision&mission" component={VisionMission} />
            <Route path="/aboutus" component={About} />
            <Route path="/eligiblity" component={Eligibility} />
            <Route path="/signup" component={SignUp} />

            {/* offered programs */}
            <Route path="/offeredProgram" component={OfferedPrograme} />
            <Route path="/Bba-detail" component={BBa} />
            <Route path="/Bsit-detail" component={Bsit} />
            <Route path="/Bscs-detail" component={Bscs} />
            <Route path="/Bsed-detail" component={Bsed} />

            <Route path="/director-message" component={DirectorMsg} />
            <Route path="/admission" component={Admission} />
            <Route path="/admission-schedule" component={AdmissionSchedule} />
            <Route path="/how-to-apply" component={HowToApply} />
            <Route path="/fee-structure" component={FeeStructure} />
            <Route path="/gallery" component={Gallery} />
            <Route path="/grading" component={Gradding} />
            <Route path="/academic-calender" component={AcademicCalender} />
            <Route path="/lsm-facilities" component={LsmFacitities} />
            <Route path="/lsm-counseling" component={Conseling} />
            <Route path="/fire-safety" component={FireSafetu} />
            <Route path="/iosh-ms" component={Iosh_ms} />
            <Route path="/occupational-safety-for-engineer" component={OccupationSafety} />
            <Route path="/safety-officer" component={SafetyOfficer} />
            <Route path="/nebosh-igs" component={NBOSH_IGS} />
            <Route path="/first-aid" component={FirstAid} />
            {/* LSM Events And Functions */}
            <Route path="/lsmevents" component={lsmevents} />
            <Route path="/LSMStudents" component={LSMStudents} />
            <Route path="/Graduate2022" component={Graduate2022} />
            <Route path="/NathiyaGali" component={NathiyaGali} />
            <Route path="/MushkpuriTop" component={MushkpuriTop} />
            <Route path="/ThemeDay" component={ThemeDay} />

          </Switch>
          <Footer />
        </React.Suspense>


      </Router>
    </div>
  );
}

export default App;
