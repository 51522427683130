import React from 'react'
import { Link } from 'react-router-dom'

const TopBar = () => {

    const token = localStorage.getItem('auth_token')

    const handlelogout = () => {
        window.location.reload();
        localStorage.removeItem("auth_token");
    }
    return (
        <div className="main__topBar">
            <div className="container-fluid" >
                <div className="top-Bar-inner" >
                    <div className="left-side-bton " >
                        <div className="main__topBar__left " >
                            <div className="topBar__statusBar " >
                                <i className="fa fa-envelope" aria-hidden="true"></i>
                                <p className="topBar__statusBar-p" >Info@lsm.edu.pk</p>
                            </div>
                            <div className="topBar__statusBar " >
                                <i className="fa fa-whatsapp" aria-hidden="true"></i>
                                <p className="topBar__statusBar-p" >+92 310 3336480</p>
                            </div>
                            <div className="topBar__statusBar " >
                                <i className="fa fa-phone" aria-hidden="true"></i>
                                <p className="topBar__statusBar-p" >0423 5111184</p>
                            </div>
                        </div>
                    </div>
                    <div className="right-side-bton" >
                        <div className="main__topBar__right " >
                            {
                                token ?
                                    <div>
                                        <Link onClick={handlelogout} className="main__topBar__right-auth_btn" >Log out</Link>
                                        <Link to="/admissionForm" className="main__topBar__right-btn " >Admission Form</Link>
                                    </div>
                                    :
                                    <Link to="/admission" className="main__topBar__right-btn " >Admission Form</Link>

                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopBar
